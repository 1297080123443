<template>
  <div class="user_info">
    <Nav navType="fun_text" textCon="个人信息">
      <template v-slot:rightText>
        <span class="iconfont icongengduo" style="color:#000;font-size:24px"></span>
      </template>
    </Nav>
    <div class="content">
      <div class="head_item" @click="show_action = true">
        <div class="label">头像</div>
        <div class="value">
          <div class="head_img">
            <img :src="userData.headimgurl" alt="">
          </div>
        </div>
        <div class="arrow">
          <div class="iconfont iconqianjin" style="color:#999;font-size:13px"></div>
        </div>
        <input
          type="file"
          style="display:none;"
          @change="addImg"
          ref="inputer"
          accept="image/png,image/jpeg,image/gif,image/jpg"
        >
      </div>
      <div class="item" @click="gotoEdit(1)">
        <div class="label">昵称</div>
        <div class="value">{{userData.real_name}}</div>
        <div class="arrow">
          <div class="iconfont iconqianjin" style="color:#999;font-size:13px"></div>
        </div>
      </div>
      <div class="item">
        <div class="label">性别</div>
        <div class="value">{{userData.sex == 1? '男':userData.sex == 2? '女':'未知'}}</div>
        <div class="arrow">
          <div class="iconfont iconqianjin" style="color:#999;font-size:13px"></div>
        </div>
      </div>
      <div class="item">
        <div class="label">出生日期</div>
        <div class="value" @click="show_date_picker = true">{{userData.birthday}}</div>
        <div class="arrow">
          <div class="iconfont iconqianjin" style="color:#999;font-size:13px"></div>
        </div>
      </div>
      <div class="item" @click="gotoEdit(2)">
        <div class="label">个性签名</div>
        <div class="value">{{userData.sign}}</div>
        <div class="arrow">
          <div class="iconfont iconqianjin" style="color:#999;font-size:13px"></div>
        </div>
      </div>
    </div>
    <ActionSheet
      v-model="show_action"
      :actions="actions"
      cancel-text="取消"
      :round="false"
      @select="onSelect"
    />
    <Popup v-model="show_date_picker" position="bottom">
      <DatetimePicker 
        datetime-picker
        v-model="currentDate"
        type="date"
        :min-date="minDate"
        :max-date="maxDate"
        @confirm="saveBirtyday"
        @cancel="show_date_picker = false"
      />
    </Popup>
  </div>
</template>
<script>
import Nav from "@components/public/searchTop";
import Setting from "@/api/setting"
import { ActionSheet, DatetimePicker, Popup } from 'vant';
export default {
  data() {
    return {
      userData:{},
      show_action:false,
      minDate:new Date('1900-1-1'),
      maxDate:new Date(),
      currentDate:new Date(),
      show_date_picker:false,
      actions:[
        { name: '拍照' },
        { name: '从相册选择' }
      ],
    };
  },
  mounted(){
    this.getData()
  },
  methods:{
    async getData(){
      try {
        const res = await Setting.GetInfo();
        if (res.code == 200) {
          this.userData = Object.assign({},res.data)
        } else{
          this.$dialog.confirm({
            title: res.msg,
          }).then(() => {
            this.$router.puth('/userlogin');
            // on confirm
          }).catch(() => {
            // on cancel
          });
        }
      } catch(err) {
        console.log(err);
      }
    },
    // 上传头像
    async addImg() {
      console.log(this.$refs.inputer.files)
      try {
        const res = await Setting.upImg(this.$refs.inputer.files[0], 5);
        var url = res.data.url
        this.save(url,1)
      } catch (err) {
        console.log(err);
      }
    },
    // 保存 1.头像 2.生日
    async save(param,type){
      var data = Object.assign({},this.userData)
      data.headimgurl = type == 1? param:data.headimgurl;
      data.birthday = type == 2? param:data.birthday;
      try {
        const res = await Setting.EditUserInfo(data);
        if (res.code == 200) {
          this.getData()
        } else{
          console.log(res);
        }
      } catch(err) {
        console.log(err);
      }
    },
    gotoEdit(i){
      this.$router.push({path:'/edituserinfo',query:{data:this.userData,type:i}})
    },
    onSelect(item){
      console.log(item.name)
      if(item.name == '从相册选择'){
        this.$refs.inputer.click();
        this.show_action = false;
      }
    },
    saveBirtyday(value){
      console.log(value)
      var y = value.getFullYear()
      var m = value.getMonth() + 1
      var d = value.getDate()
      var time = y+'-'+m+'-'+d
      this.save(time,2)
      this.show_date_picker = false;
    },
  },
  components: {
    Nav,
    ActionSheet,
    DatetimePicker,
    Popup
  }
};
</script>
<style lang="scss" scoped>
.user_info {
  width: 100%;
  height: auto;
  padding-top: 52px;
  background-color: #f4f4f4;
  font-family:PingFang SC;
  padding-bottom: 1px;
  min-height: 100vh;
  .content{
    width:100%;
    padding:0 12px;
    background-color: #fff;
    margin-bottom: 8px;
    .head_item{
      display: flex;
      div{
        height: 70px;
        line-height: 70px;
      }
    }
    .item{
      display: flex;
      border-top: 1px solid #f4f4f4;
      div{
        height: 45px;
        line-height: 45px;
      }
    }
    .label{
      color:#333;
      font-size: 14px;
      width: 119px;
    }
    .value{
      line-height: 62px;
      color:#999;
      font-size: 13px;
      text-align: right;
      flex: 1;
      overflow:hidden;
      white-space:nowrap;
      text-overflow:ellipsis;
      .head_img{
        margin-top: 12px;
        width: 46px;
        height: 46px;
        border-radius: 23px;
        background-color: #ccc;
        float: right;
        img{
          width: 46px;
          height: 46px;
          border-radius: 23px;
        }
      }
    }
    .cf80{
      color:#ff8000;
    }
    .arrow{
      margin-left: 4px;
      line-height: 62px;
      width:7px;
    }
  }
}
</style>
